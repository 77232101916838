import styled from 'styled-components';

type StyledVesselStoryPortSectionProps = {
  widthRatio: number;
  highlightRatio: number;
};

export const StyledVesselStoryPortSection = styled.div<StyledVesselStoryPortSectionProps>`
  display: flex;
  height: 100%;
  width: ${({widthRatio}) => widthRatio}%;
  border: 1px solid black;
  margin-left: -1px;
  justify-content: center;

  .load-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: -32px;
    font-size: 0.4rem;
    font-weight: bold;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 0.7rem;
    }
  }

  .unload-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 200px;
    font-size: 0.4rem;
    font-weight: 500;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 0.7rem;
    }
  }

  .port-name {
    display: flex;
    justify-content: center;
    position: absolute;
    writing-mode: tb-rl;
    height: 100%;
    align-items: center;
    transform: rotate(-180deg);
    font-size: 16px;
    font-weight: 500;
    font-family: inherit;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 18px;
    }
  }

  .duration {
    margin-top: 5px;
    font-size: 0.5rem;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 0.8rem;
    }
  }

  .vessel-story-port-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 0.4rem;
    font-weight: bold;

    @media screen and (min-width: ${({theme}) => theme.breakpoints.md}px) {
      font-size: 0.7rem;
    }

    .highlighted {
      display: flex;
      width: 100%;
      align-items: end;
      justify-content: center;
      padding-top: 2px;
    }
  }
`;
