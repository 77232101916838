import React from 'react';
import {StyledVesselStoryChart} from './index.styled';
import VesselStoryVoyageSection from './VesselStoryVoyageSection';
import VesselStoryPortSection from './VesselStoryPortSection';
import {roundNumber} from '@erkport/helpers/NumberFormatter';

export type VesselStorySectionProps = {
  duration?: number;
  durationRate: number;
  netLoad: number;
  wait?: number;
  fillRate: number;
  load?: number | null;
  unload?: number | null;
  portName?: string | null;
  threeMonths?: boolean;
};

type VesselActivityType = {
  portName: string;
  fromCluster: string;
  toCluster: string;
  duration: number;
  fromDate: string;
  toDate: string;
  port: string;
  fillRate: number;
  netLoad: number;
  loadM2: number;
  unloadM2: number;
  loadM3: number;
  unloadM3: number;
  waitErk: number;
  waitOther: number;
};

type VesselStoryChartType = {
  vesselActivities?: VesselActivityType[];
  threeMonths?: boolean;
  loadUnit: string;
};

const minDurationRate = 2;
const VesselStoryChart: React.FC<VesselStoryChartType> = ({
  vesselActivities,
  threeMonths,
  loadUnit,
}) => {
  const portActivities = vesselActivities.filter((x) => x.port != 'Voyage');
  const portCount = portActivities.length;
  const portDuration = portActivities.reduce(
    (total, p) => p.duration + total,
    0,
  );
  const voyageActivities = vesselActivities.filter((x) => x.port == 'Voyage');
  const voyageCount = voyageActivities.length;
  const voyageDuration = voyageActivities.reduce(
    (total, p) => p.duration + total,
    0,
  );

  const totalCount = portCount + voyageCount;
  const totalDuration = portDuration + voyageDuration;

  const dynamicDurationRate = (100 - minDurationRate * totalCount) * 1.1;

  return (
    <StyledVesselStoryChart style={{paddingBottom: threeMonths ? 20 : 0}}>
      <span className='load-text'>Load</span>
      <span className='dispatch-text'>
        Dispatch
        <br />
        <b>Wait</b>
      </span>
      {vesselActivities.map((section, key) =>
        section.port == 'Voyage' ? (
          <VesselStoryVoyageSection
            key={key}
            netLoad={roundNumber(section.netLoad, 1)}
            fillRate={roundNumber(section[`fillRate${loadUnit}`], 1)}
            duration={roundNumber(section.duration, 1)}
            durationRate={roundNumber(
              minDurationRate +
                minDurationRate * 1.1 +
                (section.duration * dynamicDurationRate) / totalDuration,
              1,
            )}
            threeMonths={threeMonths}
          />
        ) : (
          <VesselStoryPortSection
            key={key}
            netLoad={roundNumber(section.netLoad, 1)}
            fillRate={roundNumber(section[`fillRate${loadUnit}`], 1)}
            durationRate={roundNumber(
              minDurationRate +
                (section.duration * dynamicDurationRate) / totalDuration,
              1,
            )}
            wait={section.waitErk || 0 + section.waitOther || 0}
            portName={section.port}
            load={roundNumber(section[`load${loadUnit}`], 0)}
            unload={roundNumber(section[`unload${loadUnit}`], 0)}
            threeMonths={threeMonths}
          />
        ),
      )}
    </StyledVesselStoryChart>
  );
};

export default VesselStoryChart;
