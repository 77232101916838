import VesselPortChart from './components/vesselPortChart';
import VesselStoryChart from './components/VesselStoryChart';
import AppCard from '@erkport/components/AppCard';
import AppRowContainer from '@erkport/components/AppRowContainer';
import moment from '@erkport/services/moment';
import {Col} from 'antd';
import React, {useEffect, useState} from 'react';
import {MdBarChart, MdPieChart} from 'react-icons/md';
import {getDataGroup1} from '@erkport/services/erkport';
import Loading from '@erkport/components/AppLoadable/Loading';
import {PageWithFilterProps} from '@erkport/components/PageWrapper';
import {formatNumber, formatNumberMK} from '@erkport/helpers/NumberFormatter';
import AppFlexItem from '@erkport/components/AppFlexItem';
import {useIntl} from 'react-intl';
import StateCardWithBarChart from '@erkport/components/AppDashboard/StateCardWithBarChart';
import {getLastMonths} from '@erkport/helpers/DateHelper';

interface VesselStoryPageProps extends PageWithFilterProps {}

const VesselStoryPage = ({vessel, date, loadUnit}: VesselStoryPageProps) => {
  const [data, setData] = useState<any[]>(null);

  const {messages} = useIntl();

  useEffect(() => {
    getDataGroup1().then((x) => setData(x));
  }, []);

  if (!data) {
    return <Loading />;
  }

  const last12Months = getLastMonths(
    moment(date).year(),
    moment(date).month() + 1,
    12,
  );

  const vesselActivities = data
    .filter(
      (x) => x.shipName == vessel && moment(x.fromDate).isBefore(moment(date)),
    )
    .sort((a, b) => (a.fromDate > b.fromDate ? 1 : -1));

  const vesselActivitiesFor12Months = vesselActivities.filter((x) =>
    moment(x.toDate).isAfter(moment(date).subtract(12, 'month')),
  );

  const vesselActivitiesFor3Months = vesselActivitiesFor12Months.filter((x) =>
    moment(x.toDate).isAfter(moment(date).subtract(3, 'month')),
  );

  const vesselActivitiesFor1Month = vesselActivitiesFor3Months.filter((x) =>
    moment(x.toDate).isAfter(moment(date).subtract(1, 'month')),
  );

  const portWaitingDataFor1Month = vesselActivitiesFor1Month
    .filter((x) => x.port != 'Voyage')
    .map((x) => ({x: x.port, waitErk: x.waitErk, waitTotal: x.duration}));

  const voyagesFor1Month = vesselActivitiesFor1Month.filter(
    (x) => x.port == 'Voyage',
  );

  const voyagesFor3Months = vesselActivitiesFor3Months.filter(
    (x) => x.port == 'Voyage',
  );

  const voyagesFor12Months = vesselActivitiesFor12Months
    .filter((x) => x.port == 'Voyage')
    .map((x) => {
      const toDate = moment(x.toDate);
      const fromDate = moment(x.fromDate);

      if (fromDate.month() != toDate.month()) {
        const totalDuration = moment
          .duration(fromDate.diff(toDate))
          .asMinutes();
        const fromRatio =
          moment.duration(fromDate.diff(fromDate.endOf('month'))).asMinutes() /
          totalDuration;
        const toRatio = 1 - fromRatio;

        return [
          {
            ...x,
            profit: fromRatio * x.profit,
            income: fromRatio * x.income,
            cost: fromRatio * x.cost,
            fillRateM2: fromRatio * x.fillRateM2,
            fillRateM3: fromRatio * x.fillRateM3,
            year: fromDate.year(),
            month: fromDate.month() + 1,
          },
          {
            ...x,
            profit: toRatio * x.profit,
            income: toRatio * x.income,
            cost: toRatio * x.cost,
            fillRateM2: toRatio * x.fillRateM2,
            fillRateM3: toRatio * x.fillRateM3,
            year: toDate.year(),
            month: toDate.month() + 1,
          },
        ];
      }

      return [
        {
          ...x,
          year: moment(x.toDate).year(),
          month: moment(x.toDate).month() + 1,
        },
      ];
    })
    .flatMap((x) => x);

  const voyages12MonthsAggregated = voyagesFor12Months.reduce((acc, cur) => {
    return {
      ...acc,
      [`${cur.year}-${cur.month}`]: {
        ...cur,
        profit:
          (acc[`${cur.year}-${cur.month}`]?.profit || 0) + (cur.profit || 0),
        income:
          (acc[`${cur.year}-${cur.month}`]?.income || 0) + (cur.income || 0),
        cost: (acc[`${cur.year}-${cur.month}`]?.cost || 0) + (cur.cost || 0),
      },
    };
  }, {});

  const voyagesFor12MonthsGraphData = last12Months
    .map((x) => {
      const key = `${x.year}-${x.month}`;
      const value = (voyages12MonthsAggregated[key] || {
        profit: 0,
        cost: 0,
        income: 0,
      }) as any;
      return {
        ...value,
        key,
        year: x.year,
        month: x.month,
      } as any;
    })
    .sort((a, b) =>
      a.key.split('-').join('') > b.key.split('-').join() ? 1 : -1,
    );

  return (
    <>
      <AppRowContainer>
        <Col xs={24}>
          <AppRowContainer>
            <Col xs={24}>
              <AppCard
                title={`${messages['charts.oneMonthVoyageHistory']} (${moment(
                  vesselActivitiesFor1Month[0]?.fromDate,
                ).format('DD.MM.YYYY')} - ${moment(
                  vesselActivitiesFor1Month.at(-1)?.toDate,
                ).format('DD.MM.YYYY')})`}
                bodyStyle={{padding: '55px 45px'}}
              >
                <VesselStoryChart
                  vesselActivities={vesselActivitiesFor1Month}
                  loadUnit={loadUnit}
                />
              </AppCard>
            </Col>
          </AppRowContainer>
          <AppRowContainer>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.profitability'] as string}
                value={`%${formatNumber(
                  (voyagesFor1Month.reduce(
                    (total, cur) => total + Number(cur.profit || 0),
                    0,
                  ) *
                    100) /
                    voyagesFor1Month.reduce(
                      (total, cur) => total + Number(cur.income || 0),
                      0,
                    ),
                  2,
                )}`}
                graphData={voyagesFor12MonthsGraphData.map((x) => ({
                  x: x.key,
                  y2: (x.profit || 0) / (x.income || 1),
                }))}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.profit'] as string}
                value={`$${formatNumberMK(
                  voyagesFor1Month.reduce(
                    (total, cur) => total + Number(cur.profit || 0),
                    0,
                  ),
                  2,
                )}`}
                graphData={voyagesFor12MonthsGraphData.map((x) => ({
                  x: x.key,
                  y2: x.profit,
                }))}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.totalCost'] as string}
                value={`$${formatNumberMK(
                  voyagesFor1Month.reduce(
                    (total, cur) => total + Number(cur.cost || 0),
                    0,
                  ),
                  2,
                )}`}
                graphData={voyagesFor12MonthsGraphData.map((x) => ({
                  x: x.key,
                  y2: x.cost,
                }))}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.voyageCountEmptyAndTotal'] as string}
                value={`${voyagesFor1Month
                  .filter((x) => Number(x[`fillRate${loadUnit}`] || 0) <= 0.1)
                  .length.toString()} / ${voyagesFor1Month.length.toString()}`}
                graphData={last12Months.map((x) => {
                  const total = voyagesFor12Months.filter(
                    (v) => v.year == x.year && v.month == x.month,
                  );
                  const empty = total.filter(
                    (x) => Number(x[`fillRate${loadUnit}`] || 0) <= 0.1,
                  );
                  return {
                    x: `${x.year}-${x.month}`,
                    y1: empty.length,
                    y2: total.length - empty.length,
                  };
                })}
                stackedBar
                labels={{
                  y1: messages['common.emptyVoyage'],
                  y2: messages['common.filledVoyage'],
                }}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.loadRatio'] as string}
                highlightValue
                value={`${formatNumber(
                  voyagesFor1Month.reduce(
                    (total, cur) => ({
                      rate:
                        total.rate +
                        Number(cur[`fillRate${loadUnit}`] || 0) *
                          Number(cur.duration || 0),
                      duration: total.duration + Number(cur.duration || 0),
                      result:
                        (total.result * total.duration +
                          Number(cur[`fillRate${loadUnit}`] || 0) *
                            Number(cur.duration || 0)) /
                        (total.duration + Number(cur.duration || 0)),
                    }),
                    {rate: 0, duration: 0, result: 0},
                  ).result,
                  2,
                )}%`}
                graphData={last12Months.map((x) => {
                  const monthData = voyagesFor12Months.filter(
                    (v) => v.year == x.year && v.month == x.month,
                  );

                  return {
                    x: `${x.year}-${x.month}`,
                    y2: monthData.reduce(
                      (total, cur) => ({
                        rate:
                          total.rate +
                          Number(cur[`fillRate${loadUnit}`] || 0) *
                            Number(cur.duration || 0),
                        duration: total.duration + Number(cur.duration || 0),
                        result:
                          (total.result * total.duration +
                            Number(cur[`fillRate${loadUnit}`] || 0) *
                              Number(cur.duration || 0)) /
                          (total.duration + Number(cur.duration || 0)),
                      }),
                      {rate: 0, duration: 0, result: 0},
                    ).result,
                  };
                })}
              />
            </AppFlexItem>
          </AppRowContainer>
        </Col>
      </AppRowContainer>
      <AppRowContainer>
        <Col xs={24}>
          <AppRowContainer>
            <Col xs={24}>
              <AppCard
                title={messages['charts.oneMonthPortWaits'] as string}
                bodyStyle={{padding: '40px 30px 40px 30px'}}
              >
                <VesselPortChart
                  containerName='vessel-port-chart-1'
                  data={portWaitingDataFor1Month}
                  height={200}
                />
              </AppCard>
            </Col>
          </AppRowContainer>
        </Col>
      </AppRowContainer>
      <AppRowContainer>
        <Col xs={24}>
          <AppRowContainer>
            <Col xs={24}>
              <AppCard
                title={`${messages['charts.threeMonthVoyageHistory']} (${moment(
                  vesselActivitiesFor3Months[0]?.fromDate,
                ).format('DD.MM.YYYY')} - ${moment(
                  vesselActivitiesFor3Months.at(-1)?.toDate,
                ).format('DD.MM.YYYY')})`}
                bodyStyle={{padding: '40px 30px 60px 30px'}}
              >
                <VesselStoryChart
                  vesselActivities={vesselActivitiesFor3Months}
                  loadUnit={loadUnit}
                  threeMonths
                />
              </AppCard>
            </Col>
          </AppRowContainer>
          <AppRowContainer>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.profitability'] as string}
                value={`%${formatNumber(
                  (voyagesFor3Months.reduce(
                    (total, cur) => total + Number(cur.profit || 0),
                    0,
                  ) *
                    100) /
                    voyagesFor3Months.reduce(
                      (total, cur) => total + Number(cur.income || 0),
                      0,
                    ),
                  2,
                )}`}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.profit'] as string}
                value={`$${formatNumberMK(
                  voyagesFor3Months.reduce(
                    (total, cur) => total + Number(cur.profit || 0),
                    0,
                  ),
                  2,
                )}`}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.totalCost'] as string}
                value={`$${formatNumberMK(
                  voyagesFor3Months.reduce(
                    (total, cur) => total + Number(cur.cost || 0),
                    0,
                  ),
                  2,
                )}`}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.voyageCountEmptyAndTotal'] as string}
                value={voyagesFor3Months.length.toString()}
              />
            </AppFlexItem>
            <AppFlexItem>
              <StateCardWithBarChart
                title={messages['common.loadRatio'] as string}
                highlightValue={true}
                value={`${formatNumber(
                  voyagesFor3Months.reduce(
                    (total, cur) => ({
                      rate:
                        total.rate +
                        Number(cur[`fillRate${loadUnit}`] || 0) *
                          Number(cur.duration || 0),
                      duration: total.duration + Number(cur.duration || 0),
                      result:
                        (total.result * total.duration +
                          Number(cur[`fillRate${loadUnit}`] || 0) *
                            Number(cur.duration || 0)) /
                        (total.duration + Number(cur.duration || 0)),
                    }),
                    {rate: 0, duration: 0, result: 0},
                  ).result,
                  2,
                )}%`}
              />
            </AppFlexItem>
          </AppRowContainer>
        </Col>
      </AppRowContainer>
    </>
  );
};

export default VesselStoryPage;
