import styled from 'styled-components';

export const StyledVesselStoryChart = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  position: relative;
  margin-left: 10px;

  .load-text {
    position: absolute;
    top: -24px;
    left: -20px;
    font-size: 12px;
  }
  .dispatch-text {
    position: absolute;
    left: -34px;
    bottom: -45px;
    font-size: 12px;
  }
`;
